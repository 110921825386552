body {
  margin: 0;
  height: 100%;
  width: 100%;
}

#root {
  margin: 0;
  height: 100%;
  width: 100%;
}

.rotate-icon:hover {
  transform: rotate(180deg);
  cursor: pointer;
}

.inverted-color {
  filter: invert(100%);
}

::-webkit-scrollbar {
  width: 8px;
  padding: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-border-radius: 10px;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  margin: 1px;
  background: #67b94b; 
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #67b94b; 
}

#wasteland {
  display: block;
  background-color: #b78e63; /* Grey background color */
}

.snowflake {
  animation: fallAnimation linear infinite;
}

@keyframes fallAnimation {
  to {
    transform: translateY(20vh) translateX(100vw);
  }
}

/* GENERIC SECTION */

.page-title {
  color: #67b94b; 
  text-align: center; 
  font-size: 25px; 
  padding: 5px; 
  font-style: italic; 
  font-weight: 700;
}

/* ONLINE SECTION */

.news {
  padding-left: 5px;
  padding-right: 5px;
}

.online-title {
  color: #000000; 
  background-color: #67b94b;
  padding: 10px; 
  font-size: 20px; 
  font-style: italic; 
  font-weight: 600;
}

/* BATTLEGROUND SECTION */
.battleground-italic-text {
  color: #67b94b; 
  padding-top: 10px; 
  text-align: center; 
  font-size: 12px; 
  font-style: italic;
}

.battleground-battle-text {
  width: 100%; 
  margin: 12px auto auto auto; 
  color: #ffffff; 
  text-align: center;
}

.battleground-link {
  color: #67b94b; 
  margin: auto; 
  width: fit-content; 
  text-align: center; 
  text-decoration: underline; 
  padding-top: 5px; 
  padding-bottom: 5px;
  cursor: pointer;
}

.battleground-monster-text {
  color: #ffffff;
  width: fit-content; 
  max-width: 400px; 
  margin: auto; 
  text-align: center;
}